import React from 'react'
// import { Card as CardComponent } from 'theme-ui'
// import { Stack, Main } from '@layout'
// import CardList from '@components/CardList'
// import Card from '@components/Card'
// import Divider from '@components/Divider'
// import Seo from '@widgets/Seo'
// import { PostImage, PostBody, PostComments, PostTagsShare } from '@widgets/Post'

import { Card as CardComponent } from 'theme-ui'
import { Stack, Main, Sidebar } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Sticky from '@components/Sticky'
import Seo from '@widgets/Seo'
import AuthorCompact from '@widgets/AuthorCompact'
import Categories from '@widgets/Categories'
import {
  PostHead,
  PostImage,
  PostBody,
  PostComments,
  PostTagsShare,
  PostFooter
} from '@widgets/Post'
import { useBlogCategories } from '@helpers-blog'
import Social from '@widgets/Social'

const Post = ({
  data: { post, tagCategoryPosts, tagPosts, categoryPosts, previous, next },
  location,
  ...props
}) => {
  const relatedPosts = [
    ...(tagCategoryPosts ? tagCategoryPosts.nodes : []),
    ...(tagPosts ? tagPosts.nodes : []),
    ...(categoryPosts ? categoryPosts.nodes : [])
  ]
  const { pageContext: { services = {}, siteUrl } = {} } = props
  const categories = useBlogCategories()

  return (
    <>
      <Seo {...post} siteUrl={siteUrl} />
      <Divider />
      <Stack>
        <PostHead {...post} />
      </Stack>
      <Divider />
      <Stack>
        <Main>
          <CardComponent variant='paper'>
            <PostImage {...post} inCard />
            <PostBody {...post} />
            <PostTagsShare {...post} location={location} />
            {services.disqus && <PostComments {...post} />}
            <PostFooter {...{ previous, next }} />
          </CardComponent>
        </Main>
        <Sidebar>
          {/*<AuthorCompact author={post.author} omitTitle />
  <Divider />*/}
          <Sticky>
            <Categories categories={categories} />
            <Divider />
            {post.category && (
              <CardList
                title='Related Posts'
                nodes={relatedPosts}
                variant='horizontal-aside'
                omitMedia
                omitCategory
                limit={6}
                distinct
                aside
              />
            )}
            <Divider />
            <Social category={post.category} />
          </Sticky>
        </Sidebar>
      </Stack>
      {/*<Seo {...post} siteUrl={siteUrl} />
      <Divider />
      <Stack effect='fadeInDown'>
        <Main>
          <Card {...post} variant='horizontal-hero' omitExcerpt omitMedia />
        </Main>
      </Stack>
      <Divider space={3} />
      <Stack>
        <Main>
          <CardComponent variant='paper-lg'>
            <PostImage {...post} inCard />
            <PostBody {...post} />
            <PostTagsShare {...post} location={location} />
            {services.disqus && <PostComments {...post} />}
          </CardComponent>
          <Divider />
          {post.category && (
            <CardList
              title='Related Posts'
              nodes={relatedPosts}
              variant={['horizontal-aside']}
              columns={[1, 2, 2, 2]}
              limit={6}
              distinct
              // omitMedia
              // omitCategory
            />
          )}
        </Main>
      </Stack>*/}
    </>
  )
}

export default Post
