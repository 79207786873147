import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'
// import Navigation from '@components/Navigation'
import useSiteMetadata from '@helpers/useSiteMetadata'
// import attachSocialIcons from '@helpers/attachSocialIcons'
import { Box, Button } from 'theme-ui'
import { Link } from 'gatsby'

const Social = ({ category }) => {

  // const { social } = useSiteMetadata()
  const styles = {
    buttonContainer: {
      textAlign: 'center'
    }
  }

  if(category && category.name === "Subscriptions"){
    return (
      <Section aside title='Viadog Subscriptions'>
        <Card variant='paper'>
          <Text variant='p'>
            Helping reduce subscription churn, get started with our free in depth analytics today.
          </Text>
          <Box sx={styles.buttonContainer}>
            <Button as={Link} to='/subscriptions' variant='primary'>
              Get Started
            </Button>
          </Box>
          {/*<Navigation items={attachSocialIcons(social)} iconOnly />*/}
        </Card>
      </Section>
    )  
  }

  return (
    <Section aside title='Get in touch'>
      <Card variant='paper'>
        <Text variant='p'>
          Do you want to work with us? Get in touch today.
        </Text>
        <Box sx={styles.buttonContainer}>
          <Button as={Link} to='/contact' variant='primary'>
            Contact Us
          </Button>
        </Box>
        {/*<Navigation items={attachSocialIcons(social)} iconOnly />*/}
      </Card>
    </Section>
  )
}

export default Social
